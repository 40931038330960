<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Versionen des Artikels vergleichen">
      <div v-loading="loading">
        <div v-if="!loading" class="actions-buttons-wrapper">
          <div>
            <el-button
              v-if="reviewData.fork.differs && reviewData.newer === 'fork'"
              icon="fal fa-long-arrow-right"
              @click="updateFromOriginal"
            >
              Inhalt vom Ursprungsartikel übernehmen</el-button
            >
          </div>
          <div>
            <el-button
              v-if="reviewData.fork.differs && reviewData.newer === 'original'"
              type="primary"
              icon="fal fa-long-arrow-left"
              @click="updateFromOriginal"
            >
              Änderungen vom Ursprung übernehmen</el-button
            >
          </div>
        </div>
        <div v-if="!loading" class="diff-container">
          <div
            class="left"
            :class="{ highlighted: reviewData.newer === 'original' }"
          >
            <div>
              Zuletzt geändert: {{ lastChangeLeft }}
              <span v-if="reviewData.newer === 'original'"
                >|
                <router-link
                  :to="{
                    name: 'ShowArticleCollectionPage',
                    params: { id: reviewData.fork.collection_id }
                  }"
                  >Zum Artikel</router-link
                >
              </span>
            </div>
            <h2>
              {{ leftTitle }}
            </h2>
            <h1>
              {{ reviewData.title }}
            </h1>
            <hr />
            <div v-html="reviewData.diff.left"></div>
          </div>
          <div
            class="right"
            :class="{ highlighted: reviewData.newer === 'fork' }"
          >
            <div>
              Zuletzt geändert: {{ lastChangeRight
              }}<span v-if="reviewData.newer === 'fork'"
                >|
                <router-link
                  :to="{
                    name: 'ShowArticleCollectionPage',
                    params: { id: reviewData.fork.collection_id }
                  }"
                  >Zum Artikel</router-link
                >
              </span>
            </div>
            <h2>
              {{ rightTitle }}
            </h2>
            <h1>
              {{ reviewData.title }}
            </h1>
            <hr />
            <div v-html="reviewData.diff.right"></div>
          </div>
        </div>
      </div>
    </page-content>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns"
import ArticlesRepository from "@/repositories/articles_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"

export default {
  components: {
    TheBreadcrumbs,
    PageContent
  },
  mixins: [ApiErrorHandlerMixin],
  metaInfo: {
    title: "Änderungen überprüfen"
  },

  data() {
    return {
      loading: true,
      reviewData: {}
    }
  },
  computed: {
    left() {
      return this.reviewData.newer === "fork"
        ? this.reviewData.original
        : this.reviewData.fork
    },
    right() {
      return this.reviewData.newer === "original"
        ? this.reviewData.original
        : this.reviewData.fork
    },
    leftTitle() {
      return `Ältere Version (${
        this.reviewData.newer === "fork" ? "Ursprungsartikel" : "Ihr Artikel"
      })`
    },
    rightTitle() {
      return `Neuere Version (${
        this.reviewData.newer === "original"
          ? "Ursprungsartikel"
          : "Ihr Artikel"
      })`
    },
    lastChangeLeft() {
      let str = `${format(
        parseISO(this.left.last_content_change_at),
        "dd.LL.yyyy"
      )}`
      if (this.left.change_author) {
        str += ` (${this.left.change_author})`
      }
      return str
    },
    lastChangeRight() {
      let str = `${format(
        parseISO(this.right.last_content_change_at),
        "dd.LL.yyyy"
      )}`
      if (this.right.change_author) {
        str += ` (${this.right.change_author})`
      }
      return str
    }
  },
  async created() {
    try {
      this.reviewData = await ArticlesRepository.reviewChanges(
        this.$route.params.id
      )
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    updateFromOriginal() {
      this.$confirm(
        "Durch diese Aktion wird der Inhalt Ihres Artikels mit dem Inhalt des Ursprungsartikels überschrieben. Ihre eigenen Änderungen gehen dabei möglicherweise verloren. Sicher?",
        "Inhalt übernehmen",
        {
          confirmButtonClass: "el-button--primary",
          confirmButtonText: "Übernehmen",
          cancelButtonText: "Abbrechen",
          type: "warning"
        }
      )
        .then(async () => {
          try {
            await ArticlesRepository.updateFromOriginal(this.$route.params.id)
            this.$message({
              message: `Inhalt wurde erfolgreich übernommen.`,
              type: "success",
              showClose: true
            })
            this.$router.push({
              name: "ShowArticleCollectionPage",
              params: { id: this.reviewData.fork.collection_id }
            })
          } catch (error) {
            this.handleApiError(error)
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss">
.actions-buttons-wrapper {
  margin-bottom: 24px;
  display: flex;

  & > div {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.diff-container {
  background-color: #ccc;
  padding: 15px;
  display: flex;

  img {
    max-width: 100%;
  }
}

.diff-container > div {
  width: 50%;
  padding: 15px;

  & > hr {
    margin-bottom: 48px;
  }
}

.diff-container > .left > h2,
.diff-container > .left > h1 {
  color: #bbb;
}

.diff-container > .right > h2,
h.diff-container > .right > h1 {
  color: #333;
}

.diff-container > .highlighted {
  border: 2px dashed blue;
}

.diff-container > .left {
  background-color: white;
  margin-right: 12px;
}

.diff-container > .right {
  margin-left: 12px;
  background-color: white;
}

ins,
.diff-ins {
  code,
  img {
    border: 2px solid #080;
  }
  img {
    opacity: 0.75;
    filter: alpha(opacity=75);
  }
  a {
    color: #080;
    text-decoration: none;
  }
}

img.diff-ins,
code.diff-ins {
  border: 2px solid #080;
}

img.diff-ins {
  opacity: 0.75;
  filter: alpha(opacity=75);
}

.diff-ins {
  color: #080;
  background: #dfd;
}

ins {
  color: #080;
  background: #dfd;
  text-decoration: none;
}

del,
.diff-del {
  code,
  img {
    border: 2px solid #b00;
  }
  img {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  a {
    color: #b00;
    text-decoration: none;
  }
}

img.diff-del,
code.diff-del {
  border: 2px solid #b00;
}

img.diff-del {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.diff-del {
  color: #b00;
  background: #fcc;
  text-decoration: none;
}

del {
  color: #b00;
  background: #fcc;
  text-decoration: none;
}
</style>
